import findIndex from "lodash-es/findIndex"
import isEmpty from "lodash-es/isEmpty"
import naturalCompare from "string-natural-compare"
import { SORTING_ALPHABET } from "./lists"
import "select2"
import "select2/dist/css/select2.min.css"
import ClassWatcher from "../helpers/ClassWatcher"

type LinkedBy = "person"

/**
 * Sets up custom focus listeners. We cannot simply listen for `focus` and
 * `blur` events, as at the time of the `blur` event, the element still has
 * focus, so we cannot have additional checks in the callback, for example if
 * the select2 is now open and the container should still have a `focused` CSS
 * class for styling.
 * So instead, we listen for classname changes of the select2 container, which
 * gives us the amount of information we need and lets us also act _after_ the
 * blur event has happened and not just right before.
 */
function setUpFocusListeners($container: JQuery) {
  const $select2Container = $container.find(".select2.select2-container")
  const onFocusCallback = () => {
    $container.addClass("focused")
  }
  const onFocusLossCallback = () => {
    if (!$select2Container.hasClass("select2-container--open"))
      $container.removeClass("focused")
  }

  new ClassWatcher(
    $select2Container.get(0)!,
    "select2-container--focus",
    onFocusCallback,
    onFocusLossCallback
  )
}

const Selects = {
  linked: {
    options: [],
    selectLinkedOption(
      linkedBy: LinkedBy,
      $option: JQuery<HTMLOptionElement>
    ): void {
      const linkedByIndex = findIndex(
          Selects.linked.options,
          linkedBy as string
        ),
        value = parseInt($option.val() as string),
        optionIndex = findIndex(
          Selects.linked.options[linkedByIndex][linkedBy],
          value
        )

      if (optionIndex >= 0) {
        // @ts-ignore
        Selects.linked.options[linkedByIndex][linkedBy][optionIndex][value][
          "isSelected"
        ] = true
      }
    },

    unselectLinkedOption(
      linkedBy: LinkedBy,
      $option: JQuery<HTMLOptionElement>
    ): void {
      const linkedByIndex = findIndex(Selects.linked.options, linkedBy),
        value = parseInt($option.val() as string),
        optionIndex = findIndex(
          Selects.linked.options[linkedByIndex][linkedBy],
          value
        )

      if (optionIndex >= 0) {
        // @ts-ignore
        Selects.linked.options[linkedByIndex][linkedBy][optionIndex][value][
          "isSelected"
        ] = false
      }
    },

    init(): void {
      const $linkedSelects = $("select", ".input.js-linked")

      Selects.linked.options = []

      $linkedSelects.each(function () {
        const linkedBy = $(this).parents(".input").attr("data-linked-by")
        const $options = $(this).find("option")
        const optionGroupObject = {}
        let linkedByIndex = findIndex(Selects.linked.options, linkedBy)

        if (linkedBy) {
          if (linkedByIndex === -1) {
            // @ts-ignore
            optionGroupObject[linkedBy] = []
            // @ts-ignore
            Selects.linked.options.push(optionGroupObject)
            linkedByIndex = Selects.linked.options.length - 1
          }

          $options.each(function () {
            const value = parseInt($(this).val() as string)
              ? parseInt($(this).val() as string)
              : ""
            const label = $(this).html()
            const isSelected = value ? $(this).prop("selected") : false
            const optionIndex = findIndex(
              Selects.linked.options[linkedByIndex][linkedBy],
              value
            )
            const optionObject = {}

            if (optionIndex === -1) {
              // @ts-ignore
              optionObject[value] = { label, isSelected }
              // @ts-ignore
              Selects.linked.options[linkedByIndex][linkedBy].push(optionObject)
            } else {
              if (isSelected) {
                // @ts-ignore
                Selects.linked.options[linkedByIndex][linkedBy][optionIndex][
                  value
                ]["isSelected"] = true
              }
            }
          })
        }
      })
    },
  },

  initSelect2($select: JQuery<HTMLSelectElement>): void {
    const languageGerman = function (noResultsMessage: string) {
      return {
        inputTooLong(args) {
          const overChars = args.input.length - args.maximum

          return `Bitte ${overChars} Zeichen weniger eingeben`
        },
        inputTooShort(args) {
          const remainingChars = args.minimum - args.input.length

          return `Bitte ${remainingChars} Zeichen mehr eingeben`
        },
        loadingMore() {
          return "Lade mehr Ergebnisse …"
        },
        maximumSelected(args) {
          let message = `Sie können nur ${args.maximum} Eintr`

          if (args.maximum === 1) {
            message += "ag"
          } else {
            message += "äge"
          }

          message += " auswählen"

          return message
        },
        noResults() {
          return noResultsMessage
        },
        searching() {
          return "Suche …"
        },
      } as Select2.Translation
    }

    const $dropdownContainer = $select.parents(".dropdown")
    const $inputContainer = $select.parents(".input")
    const width = "100%"
    let $container: JQuery
    let linkedBy: LinkedBy
    let minimumSearchResults = 1
    let noResultsMessage = $select.attr("data-no-results")
    let placeholder = $select.attr("placeholder")
    let postfix = ""

    if (noResultsMessage == undefined || isEmpty(noResultsMessage)) {
      noResultsMessage = "Keine Übereinstimmungen gefunden."
    }

    /*
      This was added in March 2016 but I don't know why. It seems that it serves
      no purpose right now, although we might want to destroy the select2s when
      navigating away. But this needs further profiling.
      Another question is, if `select2()` can ever be a `function` and why this
      is not something like `typeof $select2.select2 === "function"`, although
      that does something different.
      https://github.com/NR-Systems/OSKAR-Server/commit/df79db7239bd944d307075653c4d323826f69955#diff-6ce36d02a08ca437f48953cfee6a0b33R959-R961
     */
    // if ($select.select2() === "function") {
    //   $select.select2("destroy")
    // }

    if ($inputContainer.length) {
      $container = $inputContainer
      linkedBy = $inputContainer.attr("data-linked-by") as LinkedBy
      postfix = "--input"
    } else if ($dropdownContainer.length) {
      $container = $dropdownContainer
      postfix = "--dropdown"
    }

    // @ts-ignore
    if ($container.hasClass("dropdown") || $container.hasClass("no-search")) {
      minimumSearchResults = Infinity // Hides the search field in the dropdown
    }

    // @ts-ignore
    if ($container.hasClass("dropdown") || $container.hasClass("no-blank")) {
      placeholder = undefined // Selects first option as default selected
    }

    $select
      .select2({
        language: languageGerman(noResultsMessage),
        minimumResultsForSearch: minimumSearchResults,
        placeholder,
        sorter(data) {
          return data.sort((a, b) => {
            return naturalCompare(a.text, b.text, {
              alphabet: SORTING_ALPHABET,
            })
          })
        },
        theme: `oskar-webapp${postfix}`,
        width,
        createTag(params) {
          if ($.trim(params.term) !== "") {
            return {
              id: params.term,
              text: params.term,
              newOption: true,
            }
          }
        },
        templateResult(data) {
          let newOption = false
          // @ts-ignore
          if (data.newOption && !data.element) {
            newOption = true
            // @ts-ignore
            delete data.newOption
          }

          if (newOption === true) {
            return $(
              $.parseHTML(
                `Maßnahmenkategorie erstellen:&nbsp;<span class="highlighted-result">${data.text}</span>`
              )
            )
          } else {
            return data.text
          }
        },
      } as Select2.Options)
      .on("select2:close", () => {
        $container.removeClass("open")
      })
      .on("select2:opening", () => {
        $container.addClass("open focused")
      })
      .on("select2:select", function () {
        const $option = $("option:selected", this) as JQuery<HTMLOptionElement>

        if (!isEmpty(linkedBy)) {
          Selects.linked.selectLinkedOption(linkedBy, $option)
          Selects.updateOptions(linkedBy)
        }
      })
      .on("select2:selecting", function () {
        const $option = $("option:selected", this) as JQuery<HTMLOptionElement>

        if (!isEmpty(linkedBy)) {
          Selects.linked.unselectLinkedOption(linkedBy, $option)
        }
      })

    /*
     * Hacky fix for a bug in select2 with jQuery 3.6.0's new nested-focus "protection"
     * see: https://github.com/select2/select2/issues/5993
     * see: https://github.com/jquery/jquery/issues/4382
     *
     * TODO: Recheck with the select2 GH issue and remove once this is fixed on their side
     */
    $select.on("select2:open", () => {
      // Cast necessary because of https://github.com/Microsoft/TypeScript/issues/14111
      ;(
        document.querySelector(".select2-search__field") as HTMLElement
      )?.focus()
    })

    // @ts-ignore
    setUpFocusListeners($container)
  },

  updateOptions(linkedBy: LinkedBy): void {
    const $selects = $(`.input[data-linked-by="${linkedBy}"]`)
      .not(":hidden")
      .find("select")
      .not(":disabled")
    const linkedByIndex = findIndex(Selects.linked.options, linkedBy)
    const options = Selects.linked.options[linkedByIndex][linkedBy]
    // @ts-ignore
    const optionsCount = options.length

    $selects.each(function () {
      const value = parseInt($(this).val() as string)
      let optionsHTML = ""
      let keyValue = null
      let attribute = null
      let data = null
      let dataAttributes = null

      for (let i = 0; i < optionsCount; i++) {
        keyValue = parseInt(Object.keys(options[i])[0])
        attribute = ""
        dataAttributes = ""

        if (keyValue) {
          // Find already existing data attributes and save them in dataAttributes to be re-added
          // to the options html
          data = $(`[value="${keyValue}"]`, this).data()
          for (const dataName in data) {
            dataAttributes += `data-${dataName}="${data[dataName]}" `
          }

          if (options[i][keyValue]["isSelected"]) {
            attribute = 'disabled="disabled" '

            if (value === keyValue) {
              attribute = 'selected="selected" '
            }
          }

          optionsHTML += `<option ${attribute}${dataAttributes}value="${keyValue}">${options[i][keyValue]["label"]}</option>`
        } else {
          optionsHTML += '<option value=""></option>'
        }
      }

      $(this).empty().append(optionsHTML).trigger("change.select2")
    })
  },

  build($targetSelects: JQuery<HTMLSelectElement>): void {
    $targetSelects.each(function () {
      const $inputContainer = $(this).parents(".input")
      const linkedBy = $inputContainer.attr("data-linked-by") as LinkedBy

      if (!isEmpty(linkedBy)) {
        Selects.updateOptions(linkedBy)
      }

      Selects.initSelect2($(this))
    })
  },

  init(): void {
    const $selects = ($("select") as JQuery<HTMLSelectElement>).filter(
      function () {
        // We want to initialize all visible selects as well as the ones that
        // might be invisible, but not disabled. This can happen for example
        // when we go to `Projects#edit` and the last row contains a hidden (not
        // visible), but not disabled select which we want to initialize as
        // well. In this case it's the select for the user role.
        return $(this).is(":visible") || !$(this).is(":disabled")
      }
    )

    Selects.linked.init()
    Selects.build($selects)
  },
}

export default Selects
