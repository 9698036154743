// To be safe and robust, polyfills/shims must run before all other code
// https://webpack.js.org/guides/shimming/
// NOTE: 2021-03-25: Removed IE11 support. All APIs we use should now be
// supported by browsers directly.
// import "../src/polyfills"

// @ts-ignore
window.jQuery = $
// @ts-ignore
window.$ = $

import "jquery.turbolinks/vendor/assets/javascripts/jquery.turbolinks"
import "../src/turbolinks_compatibility"
import Information from "../src/landing/information"
import Prices from "../src/landing/prices"
import Checkboxes from "../src/common/checkboxes"
import Fields from "../src/common/fields"
import debounce from "lodash-es/debounce"
import Rails from "@rails/ujs"
import * as Sentry from "@sentry/browser"
import Turbolinks from "turbolinks"

// @ts-ignore
window._ = { debounce }

Rails.start()
Sentry.init({
  dsn: "https://2e75a4cea86446d590194b4382a20a61@sentry.io/1456820",
})
Turbolinks.start()

document.addEventListener("turbolinks:load", () => {
  const $site = $("#site")

  if ($site.hasClass("information")) {
    Information.init()
  } else if ($site.hasClass("prices")) {
    Prices.init($site)
  }

  Checkboxes.init()
  Fields.init()
})
