/*
  Could definitely use a better name, but what this does it disables checking
  the checkbox if the element contains links and they are being clicked. For
  example, the "I agree to the ToS" text is completely clickable, but "ToS"
  would be a link. So if you want to click on the ToS, without calling this, it
  would accept the ToS by simply opening them, which is – in our case – a bug,
  but for customers it might seems like dark UX.
*/
const CheckboxLinks = {
  disable(selector: JQuery.Selector): void {
    $(selector).on("click", function (event: JQuery.Event) {
      event.stopPropagation()
    })
  },
}

export default CheckboxLinks
