function animate() {
  const $inputFields = $(
    'input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="url"], input[type="tel"]'
  )

  $inputFields.each(function () {
    const $inputContainer = $(this).parents(".input:not(.readonly), .search")

    if ($(this).attr("autofocus")) {
      $inputContainer.addClass("focused")
    }

    $(this).on("focusin.oskar", () => {
      $inputContainer.addClass("focused")
    })

    $(this).on("focusout.oskar", () => {
      $inputContainer.removeClass("focused")
    })
  })
}

const Fields = {
  init(): void {
    animate()
  },
}

export default Fields
