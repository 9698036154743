import CheckboxLinks from "./checkbox_links"
import Selects from "../common/selects"

const Information = {
  init(): void {
    CheckboxLinks.disable(".checkbox.privacy_policy a")

    // We only have a dropdown on the information page. This makes sure we don't
    // initialize select2 more than once. Fixes
    // https://github.com/NR-Systems/OSKAR-Server/issues/2012.
    // TODO: Revisit this once landing page JavaScript is moved to Webpacker.
    const select2NotInitialized =
      $("#contact_form_contact_type").data("select2") === undefined

    if (select2NotInitialized) {
      Selects.init()
    }
  },
}

export default Information
