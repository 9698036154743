import CheckboxLinks from "./checkbox_links"

function initScrollLogic($site: JQuery): void {
  const positioning = function () {
    const scrollOffset = 1172 - 232 // 1172: the actual y-position; 232: the final y-position
    const scrollPosition = $(window).scrollTop() || 0
    const $verticalBanner = $site.find(".module.banner.vertical")
    const $verticalBannerCircle = $verticalBanner.find(".circle")
    const $verticalBannerText = $verticalBanner.find("p")

    if (scrollPosition <= scrollOffset) {
      $verticalBannerCircle.css({ top: 1172 - scrollPosition + "px" })
      $verticalBannerText.css({ top: 1516 - scrollPosition + "px" })
    } else {
      $verticalBannerCircle.css({ top: "232px" })
      $verticalBannerText.css({ top: 1516 - scrollOffset + "px" })
    }
  }

  positioning()

  $(window).on("scroll.oskar", function () {
    positioning()
  })
}

const Prices = {
  init($site: JQuery): void {
    CheckboxLinks.disable(".checkbox.avb a")

    $(window).off("scroll.oskar")
    initScrollLogic($site)
  },
}

export default Prices
