// Taken from https://stackoverflow.com/a/53914092/1928168
class ClassWatcher {
  targetNode: Element

  classToWatch: string

  classAddedCallback: () => void

  classRemovedCallback: () => void

  observer: MutationObserver | null

  lastClassState: boolean

  constructor(
    targetNode: Element,
    classToWatch: string,
    classAddedCallback: () => void,
    classRemovedCallback: () => void
  ) {
    this.targetNode = targetNode
    this.classToWatch = classToWatch
    this.classAddedCallback = classAddedCallback
    this.classRemovedCallback = classRemovedCallback
    this.observer = null
    this.lastClassState = targetNode.classList.contains(this.classToWatch)

    this.init()
  }

  init(): void {
    this.observer = new MutationObserver(this.mutationCallback)
    this.observe()
  }

  observe(): void {
    this.observer?.observe(this.targetNode, { attributes: true })
  }

  disconnect(): void {
    this.observer?.disconnect()
  }

  mutationCallback: MutationCallback = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (
        mutation.type === "attributes" &&
        mutation.attributeName === "class"
      ) {
        const currentClassState = (
          mutation.target as Element
        ).classList.contains(this.classToWatch)
        if (this.lastClassState !== currentClassState) {
          this.lastClassState = currentClassState
          if (currentClassState) {
            this.classAddedCallback()
          } else {
            this.classRemovedCallback()
          }
        }
      }
    }
  }
}

export default ClassWatcher
